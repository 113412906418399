import { Component, HostListener, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { GlowService } from '../../services/glow.service';
import { VirtualEntityService } from '../../services/virtual-entity.service';
import { ResourceTypeConfigService } from '../../services/configuration.service';
import { tLoadingElement } from '../../services/app-config.typings';
import { CustomUIConfigService } from '../../services/custom-ui-config.service';
import { PicardService } from 'src/app/services/picard.service';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { ResponseModalComponent } from 'src/app/components/response-modal/response-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ServiceSelectorSvc } from 'src/app/services/service-selector.service';
import { tResourceTypeMapping } from 'src/app/services/configuration.typings';

declare var require: any;
var FileSaver = require('file-saver');

@Component({
  selector: 'app-dashboard',
  styleUrls: ['./dashboard.component.scss', '../../pages/pages.component.scss'],
  templateUrl: './dashboard.component.html',
})

export class DashboardComponent {

  @Input() consumptionType;

  @HostListener('window:resize', []) onResize() {
    this.innerWidth = window.innerWidth;
  }

  pageId: string = 'Dashboard';
  innerWidth: number;
  pageTitle: string = 'My Data';
  loadObj: tLoadingElement;
  cookieClass: boolean = false;
  subscriptionGroupResourceData: Subscription;
  subscriptionPicardGroupConsumptionData: Subscription;
  subscriptionSensorVirtualEntityList: Subscription;
  subscriptionSelectedService: Subscription;
  resourceTypeMapping: tResourceTypeMapping;
  loading: boolean = true;
  resourceIdArray: any[];
  veList: any[];
  classifierToResourceTypeMap: any;
  dimension: string = 'energy';
  groupResource: any;
  selectedService: any;

  aggregateSelector = [
    { value: 'halfHourly', display: '30min' },
    { value: 'day', display: 'Day' },
    { value: 'month', display: 'Month' },
    { value: 'year', display: 'Year' },
  ];

  sensorVEList: any;

  constructor(
    private glowService: GlowService,
    private uiConfigService: CustomUIConfigService,
    private resourceTypeConfigService: ResourceTypeConfigService,
    private veService: VirtualEntityService,
    private picardService: PicardService,
    private modalService: NgbModal,
    private serviceSelectorSvc: ServiceSelectorSvc,
    private ccService: NgcCookieConsentService,
  ) {

    this.resourceTypeMapping = this.resourceTypeConfigService.loadResourceTypeMappings();

    this.loadObj = this.uiConfigService.initialiseLoadingBooleans(false);
    this.resourceTypeConfigService.getClassifierToResourceTypeMap().then((resp) => {
      this.classifierToResourceTypeMap = resp;
    });

    this.subscriptionSelectedService = this.serviceSelectorSvc.serviceSet.subscribe(selectedService => {
      this.selectedService = selectedService;
      this.processSelectedService()
    })

    this.subscriptionGroupResourceData = this.picardService.groupResourceData$.subscribe((groupResource) => {
      if (groupResource) {
        this.groupResource = groupResource;
        this.resourceIdArray.map(async (resource) => {
          const match = this.groupResource.find((x) => x.resourceId == resource.resourceId);
          const device = await this.glowService.getResourceDevice(resource.resourceId).toPromise();
          resource.description = device.description;
          resource.hardwareId = device.hardwareId;
          resource.firstReadingTime = match.firstReadingTime;
          resource.downloadAvailable = match ? true : false;
        });

      }
    });

    this.subscriptionPicardGroupConsumptionData = this.picardService.picardConsumptionData$.subscribe((data) => {
      this.downloadResult(data[0]);
    });

    if (this.ccService.hasAnswered() && this.ccService.hasConsented()) {
      this.cookieClass = true;
    }

  }

  ngOnInit() {
    this.performChecks();
    this.onResize();
    this.selectedService = this.serviceSelectorSvc.getSelectedService();
    if (this.selectedService) {
      this.processSelectedService()
    }
    this.loading = false

  }

  ngOnChanges() {
    this.performChecks();
    this.onResize();
    this.selectedService = this.serviceSelectorSvc.getSelectedService();
    if (this.selectedService) {
      this.processSelectedService()
    }
    this.loading = false

  }

  ngOnDestroy() {
    if (this.subscriptionGroupResourceData) {
      this.subscriptionGroupResourceData.unsubscribe();
    }
    if (this.subscriptionPicardGroupConsumptionData) {
      this.subscriptionPicardGroupConsumptionData.unsubscribe();
    }
    if (this.subscriptionSelectedService) {
      this.subscriptionSelectedService.unsubscribe();
    }
  }

  performChecks(): void {
    this.glowService.checkValidateExistingToken();
  }

  toggleDateRange(resourceId: string, selectedAgg: string): void {
    const match = this.resourceIdArray.find((x) => x.resourceId == resourceId);
    match.selectedAgg = selectedAgg;
  }

  processResourceDownload(resourceId: string) {
    const resource = this.groupResource.find((x) => x.resourceId == resourceId);
    const match = this.resourceIdArray.find((x) => x.resourceId == resourceId);
    this.picardService.getResourceTimeseries(resource, match.selectedAgg);
  }

  async processSelectedService() {
    this.resourceIdArray = [];

    const validClassifiers = [this.consumptionType]
    if (this.consumptionType == 'electricity.consumption') {
      validClassifiers.push('electricity.export')
    }

    const filteredResources = this.selectedService.resources.filter(x => validClassifiers.includes(x.classifier))

    filteredResources.map(resource => {
      if (this.resourceTypeMapping.dimension[this.dimension].indexOf(resource.resourceTypeId) > -1 ||
        this.resourceTypeMapping.dataStreamType['export'].indexOf(resource.resourceTypeId) > -1
      ) {
        const attributes = { label: resource.name, data: { veId: this.selectedService.veId } };
        const { resourceId, resourceTypeId, classifier, name } = resource;
        this.resourceIdArray.push({ resourceId, resourceTypeId, classifier, attributes, selectedAgg: 'month' });
      }
    });

    if (this.resourceIdArray.length > 0) {
      await this.picardService.getPicardGroupResource(this.resourceIdArray);
    }

    this.loading = false;
  }

  sendGAEvent(params) {
    if (this.ccService.hasAnswered() && this.ccService.hasConsented()) {
      window.dataLayer.push(params);
    }
  }

  async refreshResources() {
    this.loadObj = this.uiConfigService.initialiseLoadingBooleans(true);

    const refreshedResource = [];
    const lastResponse = await this.resourceIdArray.reduce((accumulatorPromise, resource) => {
      return accumulatorPromise.then(async (response) => {
        if (response) refreshedResource.push(response);
        return this.glowService.refreshResourceBinary(resource.resourceId).toPromise();
      });
    }, Promise.resolve());
    if (lastResponse) refreshedResource.push(lastResponse);

    if (refreshedResource.length > 0) {
      this.responseModal(refreshedResource);
      this.veService.findVirtualEntities();
    }

    this.loadObj.loading = false;
  }

  responseModal(resources) {
    const modalRef = this.modalService.open(ResponseModalComponent, { scrollable: true, centered: true, size: 'sm' });
    modalRef.componentInstance.resources = resources;
  }

  downloadResult(resource) {
    var date = new Date();
    var dateString = date.toISOString().split('.');
    var dateInFileName = dateString[0];
    var reportName = resource.label.replace(',', '');
    var filename = `${reportName}-${dateInFileName}.csv`;
    var csvContent = toCSV(resource);
    var blob = new Blob([csvContent], { type: 'text/json' });
    FileSaver.saveAs(blob, filename);
    this.sendGAEvent({ event: 'download' });
  }

}

function toCSV({ data: json, units }) {
  var jsonVal = Object.keys(json).map(function (e) {
    return json[e];
  });
  var csv = '';
  var keys = (json[0] && Object.keys(json[0])) || [];
  csv += ['epochTimestamp', 'kWh', 'dateTime'].join(',') + '\n';
  for (var line of jsonVal) {
    if (line[1] == null) {
      line[1] = 0
    }
    // csv += keys.map((key) => (line[key] / 1000).toFixed(3)).join(',') + ',';
    let value = units == 'kWh' ? (line[1] / 1000).toFixed(3) : line[1].toFixed(3)
    csv += (line[0] / 1000) + ","
    csv += (value > 0 ? value : 0) + ","
    csv += epochToIso(line[0]) + '\n';
  }
  return csv;
}

function epochToIso(epoch) {
  let newDate = new Date(epoch);
  let formattedDate = newDate.toISOString();
  return formattedDate;
}